import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="contact" />

    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />.
      <div className="contact-container">
        <h1>Get a Quote Today</h1>
        <h2 className="sub-heading">
          Tell us about the services you’re looking for
        </h2>
        <h2 className="sub-heading">We’ll get back to you within 24 hours</h2>
        <div className="contact-form">
          <div className="firstTwo">
            <div>
              <label htmlFor="name"> Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
              />
            </div>

            <div className="others">
              <label htmlFor="email"> email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
              />
            </div>
          </div>

          <div className="others">
            <label htmlFor="phone"> Phone Number</label>
            <input
              type="phone"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
            />
          </div>

          <div className="others">
            <label htmlFor="subject"> Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="what do you need help with?"
            />
          </div>

          <div className="others">
            <label htmlFor="message"> Message</label>
            <textarea
              rows="5"
              type="textarea"
              id="message"
              name="message"
              placeholder="what do you need help with?"
            />
          </div>
          {/* <input formAction="submit" className="btn" value="send message" /> */}
          <button type="submit" className="btn">
            send message
          </button>
        </div>
      </div>
    </form>
  </Layout>
)

export default SecondPage
